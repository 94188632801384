/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import "@fontsource/roboto";
@import "@fontsource/material-icons";
@import "@fontsource/material-icons-outlined";

.material-icons,
.material-icons-outlined {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons-outlined {
  font-family: 'Material Icons Outlined';
}

@import "bootstrap/scss/bootstrap";

@import "styles/breakpoints.scss";
@import "styles/colors.scss";
$body-bg: $light-grey;
$brand-primary: $pink;
$nav-pills-active-link-hover-bg: $pink;
$nav-tabs-link-hover-border-color: $transparent-black;
$carousel-indicator-active-bg: $dark-grey !default;
$carousel-indicator-border-color: $dark-grey !default;

@import "./styles/range.scss";

@include mat.core();

html {
  height: 100%;
}

// Hide ng cookies consent revoke button
.cc-revoke {
  display: none!important;
}


body {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #fafafa;
  overflow: auto;
  font-family: Roboto, sans-serif;
  font-weight: 300;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;

  .mat-checkbox-layout {
    overflow: hidden;
    white-space: nowrap;
  }
}

.spacer {
  flex-grow: 1;
}

// Material design theming
$my-primary: mat.m2-define-palette(mat.$m2-pink-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-blue-gray-palette, A200, A100, A400);
$my-warn: mat.m2-define-palette(mat.$m2-red-palette);

/* beautify ignore:start */
$my-button-level: mat.m2-define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 400,
  $font-size: 16px,
  $line-height: 20px,
  $letter-spacing: normal,
);

$my-text-level: mat.m2-define-typography-level(
  $font-family: 'Roboto',
  $font-weight: 300,
  $font-size: 14px,
  $line-height: 20px,
  $letter-spacing: normal,
);

$my-typography: mat.m2-define-typography-config(
  $font-family: 'Roboto',
  $body-1: $my-text-level,
  $body-2: $my-text-level,
  $button: $my-button-level,
);

$my-theme: mat.m2-define-dark-theme((
  color: (
    primary: $my-primary,
    accent: $my-accent,
    warn: $my-warn,
  ),
  typography: $my-typography,
  density: 0,
));
/* beautify ignore:end */


// Emit theme-dependent styles for common features used across multiple components.
@include mat.all-component-themes($my-theme);
@include mat.typography-hierarchy($my-typography);


// USE LIGHT THEME for some components
$my-light-primary: mat.m2-define-palette(mat.$m2-pink-palette, 500);
$my-light-accent: mat.m2-define-palette(mat.$m2-blue-gray-palette, A200, A100, A400);
$my-light-warn: mat.m2-define-palette(mat.$m2-red-palette);

/* beautify ignore:start */
$my-light-theme: mat.m2-define-light-theme((
  color: (
    primary: $my-light-primary,
    accent: $my-light-accent,
    warn: $my-light-warn,
  ),
  typography: $my-typography,
  density: 0,
));
/* beautify ignore:end */

@include mat.card-color($my-light-theme);
@include mat.form-field-color($my-light-theme);
@include mat.input-color($my-light-theme);
@include mat.checkbox-color($my-light-theme);
@include mat.tabs-color($my-light-theme);
@include mat.select-color($my-light-theme);
@include mat.datepicker-color($my-light-theme);
@include mat.menu-color($my-light-theme);
@include mat.dialog-color($my-light-theme);
@include mat.autocomplete-color($my-light-theme);

app-login,
app-change-password,
app-change-location,
app-delete-account,
app-turbotest,
sort-test-wrapper,
app-new-exercise,
favorites-list,
app-date-editor,
app-contact-editor,
app-forgotten,
app-user-data,
app-voucher,
app-contact-person-editor,
app-vacancy-editor,
app-hall-of-fame,
app-company-dating-search,
app-job-sugestions,
app-consultants,
create-test,
conversation {
  @include mat.button-color($my-light-theme);
}

conversation {
  @include mat.fab-color($my-light-theme);
}

mat-option.mat-mdc-option {
  color: black;
}
