// Range

$range-height: 14px !default;
$range-width: 14px !default;
$track-height: 3px !default;
$radio-fill-color: $pink !default;
$input-background: #fff !default;

.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

input[type=range]::selection {
  background: $pink;
}
