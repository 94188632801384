.startscreen {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: #e91e63;
}

.startscreen .centered {
	vertical-align: middle;
	display: inline-block;
	color: white;
}

.startscreen .centered h1 {
	text-align: center;
}

.startscreen .centered.block {
	position: relative;
}

.startscreen .centered .spin-container {
	display: inline-block;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-75%, -75%);
}

.spin {
	animation: spin 1s infinite linear;
}

@keyframes spin {
	from {
		transform: rotate(360deg);
	}
	to {
		transform: rotate(0deg);
	}
}
